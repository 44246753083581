<template>
  
    <s-crud @returnObject='refresh($event)'
    no-border
    title="Criterios de Cobranza"
    :config="config"
    @save="save($event)"
    edit
    add
    @clearForm="clearForm()"
    @rowSelected="rowSelected($event)"
    remove    
    :filter="filter"
    search-input  
    ref="crud"
    >
     <template scope="props">
      <v-card-text class="pa-0" >
        <v-row justify="center">

          <v-col cols="12" class="s-col-form" sm="3" md="3" lg="3" justfy="center" style="margin: initial;">
             <v-card class="pa-2">
           <sselect-client-sap clearable v-model="props.item.ClientID"  :text="props.item.CardName" label="cliente"></sselect-client-sap>
             </v-card>
          </v-col>

         <v-col cols="12" class="s-col-form" sm="3" md="3" lg="3" justfy="center" style="margin: initial;">
             <v-card class="pa-2">
             <s-select-definition v-model="props.item.TypeBoarding" label="Tipo de Transporte" :def="1280"></s-select-definition>
             </v-card>
          </v-col>
        <v-col cols="12" class="s-col-form" sm="3" md="3" lg="3" justfy="center" style="margin: initial;">
             <v-card class="pa-2">
             <s-select-definition v-model="props.item.TypeCurrency" label="Tipo de Transporte" :def="1129"></s-select-definition>
             </v-card>
          </v-col>
       <v-col cols="12" class="s-col-form" sm="3" md="3" lg="3" justfy="center" style="margin: initial;">
             <v-card class="pa-2">
            <s-text v-model="props.item.CrcAdvanceCurrency" label="Adelanto de Moneda"></s-text>
             </v-card>
          </v-col>
        <v-col cols="12" class="s-col-form" sm="3" md="3" lg="3" justfy="center" style="margin: initial;">
             <v-card class="pa-2">
            <s-text v-model="props.item.CrcDayAdvance" label="Dias de Adelanto"></s-text>
             </v-card>
          </v-col>
          <v-col cols="12" class="s-col-form" sm="3" md="3" lg="3" justfy="center" style="margin: initial;">
             <v-card class="pa-2">
            <s-text v-model="props.item.CrcDayResidue" label="Dias de Saldo"></s-text>
             </v-card>
          </v-col>
        <v-col cols="12" class="s-col-form" sm="3" md="3" lg="3" justfy="center" style="margin: initial;">
             <v-card class="pa-2">
             <s-select-definition v-model="props.item.TypeBill" label="Tipo de Factura" :def="1302"></s-select-definition>
             </v-card>
          </v-col>
          <v-col cols="12" class="s-col-form" sm="3" md="3" lg="3" justfy="center" style="margin: initial;">
             <v-card class="pa-2">
           
             <sselect-criteria-collection  clearable v-model="props.item.CraID"  label="Criterio de Adelanto"></sselect-criteria-collection>
             
             </v-card>
          </v-col>

          
          <v-col cols="12" class="s-col-form" sm="3" md="3" lg="3">
             <v-card class="pa-2">
            <s-select-definition v-model="props.item.CrcStatus" label="Estado" :def="1304"></s-select-definition>
          </v-card>
          </v-col>

        </v-row> 
       
      </v-card-text> 


          </template>
           <template v-slot:CrcStatus="{ row }">
        <v-chip style="margin:0px" x-small :color="row.CrcStatus == 1 ? 'success' : 'error'">
          {{ row.CrcStatus == 1 ? "Activa" : "Inactiva" }}
        </v-chip>
      </template>

     <template v-slot:SecStatus="{ row }">
        <v-chip style="margin:0px" x-small :color="row.SecStatus == 'Eliminado' ? 'error' : 'success'">
            {{ row.SecStatus}}
        </v-chip>
      </template> 
      
     </s-crud>

</template>


<script>
import _service from "@/services/Sale/SleCriteriaCollectionService";
import SselectClientSap from '../../../components/Sale/SselectClientSap.vue';
import SselectCriteriaCollection from '../../../components/Sale/SselectCriteriaCollection.vue';
export default {
  name: "ServiceSleCriteriaCollection",
  components: {SselectClientSap, SselectCriteriaCollection, SselectClientSap  },
  props: {
    code:{ type: String, default: "" },
       parentID: { type: Number, default: 0 },
    addNoFunction: { type: Boolean, default: false },
  },

  data() {
    return {
      CrcID:0,
      dialogEdit: false,
       ataID: 0,  
       itemsSelect: [],
       itemsFarm: [], 
       filter: { Code: ""},  
     };     
  },

  computed: {
   
    config() {
      return {
        model: {
          //RETORNAR PROPIEDAD COMPUTADA
          CrcID: "ID",
          Name: "string",         
          CraUnit: "string", 
          SecStatus: "string",
          CrcStatus: "status",
          }, 
         service: _service,
       headers:  this.$fun.getSecurity().IsLevelAdmin  
          ? [
              //Vista de tabla para admin
              { text: "ID", value: "CrcID", sortable: false },
              { text: "Cliente", value: "CardName", sortable: false },
              { text: "Transporte", value: "TypeBoardingText", sortable: false }, 
              { text: "Moneda", value: "TypeCurrencyText", sortable: false }, 
              { text: "Factura", value: "TypeBillText", sortable: false }, 
              { text: "Adelanto Moneda", value: "CrcAdvanceCurrency", sortable: false },
              { text: "Dias Adelanto", value: "CrcDayAdvance", sortable: false },
              { text: "Dias Saldo", value: "CrcDayResidue", sortable: false },
              { text: "Estado", value: "CrcStatus", sortable: false },
                     
            ]
          : [ 
                // CABECERA EN CASO NO SE LOGEE COMO ADMIN            
            { text: "ID", value: "CrcID", sortable: false },
              { text: "Cliente", value: "CardName", sortable: false },
              { text: "Transporte", value: "TypeBoardingText", sortable: false }, 
              { text: "Moneda", value: "TypeCurrencyText", sortable: false }, 
              { text: "Factura", value: "TypeBillText", sortable: false }, 
              { text: "Adelanto Moneda", value: "CrcAdvanceCurrency", sortable: false },
              { text: "Dias Adelanto", value: "CrcDayAdvance", sortable: false },
              { text: "Dias Saldo", value: "CrcDayResidue", sortable: false },
              { text: "Estado", value: "CrcStatus", sortable: false },
            ],
      };
    }, 
    
    isAdmin() {
      this.$fun.getSecurity().IsLevelAdmin;
         },  
  },
  

  methods: {

 Initialize() {
   //DedID: this.DedID -- base de datos: campo actual
     this.filter = {Code: this.code};
    },
   
    edit(item) {
      this.dialogEdit = true;
    
    },

    rowSelected(rows) {
      if (rows.length > 0) {
      this.craID = rows[0].CraID;
      this.$emit("rowSelected",  rows[0]); 
       this.itemsSelect=rows[0];
      }
    },

    save(item) {  

      if (item.CrcAdvanceCurrency.length==0)
      {
             this.$fun.alert("Debe ingresar un adelanto de moneda","warning")
             return;
      }

        item.SecStatus=1
        item.save();          
      
    },    
  },
  watch: {
    
    code(){        
      this.Initialize();    
     
      },
  },
   created() {
    this.filter = { Code: "" };
  },
};
</script>
